/**
* This is an auto generated code. This code should not be modified since the file can be overwritten
* if new genezio commands are executed.
*/

import { Remote } from "./remote.js"

export class ControllerUserData {
  static remote = new Remote("https://xeviwep2l2y3w45bo7dy645cqm0vzqfs.lambda-url.eu-central-1.on.aws/ControllerUserData")

  static async sendMessage(firstName, secondName, email, phone, message) {
    return ControllerUserData.remote.call("ControllerUserData.sendMessage", firstName, secondName, email, phone, message)
  }

}
