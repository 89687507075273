export const styledObjectConducereIT = {
  styleComponent:
    "overflow- h-auto w-full mt-10 flex flex-wrap justify-center  p-2 sm:p-10 mt-5 sm:mt-40 md:mb-[-1px] sm:mb-40 conducereComp rounded-none ",
  style:
    " mb-4 md:ml-10 md:mr-10 max-w-sm overflow-hidden h-[80%] z-auto  bg-gray-100 hover:shadow-xl hover:shadow-black/40 ",
  CardHeaderStyle:
    "  md:w-full md:!ml-0 sm:ml-0 sm:mr-0 sm:w-auto flex items-center justify-center rounded-none  w-full h-[100%] relative h-auto md:w-full md:!ml-0 sm:ml-0 sm:mr-0 sm:w-96 rounded-none  ",
  AmprentaIsActive: false,
  CardBodyStyle:
    "flex justify-center flex flex-col items-center justify-center mb-5",
  CardTitleStyle: "",
  CardTextStyle: "",
  FooterStyle: "",
  isActive: false,
};

import Furtuna from "../img/departamente/it/Furtuna.webp";
import Grozavu from "../img/departamente/it/Grozavu.webp";
import Adi from "../img/departamente/it/Adi.webp";
import Mihnea from "../img/departamente/it/Mihnea.webp";

export const mappingObjectConducereIT = [
  {
    ImgSrc: Furtuna,
    ImgAlt: "Furtuna Teodor",
    CardTitle: "Furtuna Teodor",
    CardText: "Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Grozavu,
    ImgAlt: "Grozavu Cătălin",
    CardTitle: "Grozavu Cătălin",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Mihnea,
    ImgAlt: "Aniculesei Mihnea",
    CardTitle: "Aniculesei Mihnea",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Adi,
    ImgAlt: "Bostanici Adrian",
    CardTitle: "Bostanici Adrian",
    CardText: "Secretar",
    FooterText: "",
  },
];

export const styledObjectConducereSportiv = {
  styleComponent:
    "overflow-auto w-full mt-10 flex flex-wrap justify-center  p-2 sm:p-10 mt-5 sm:mt-40 md:mb-[-1px] sm:mb-40 conducereComp rounded-none ",
  style:
    "mt-4 mb-4 md:ml-10 md:mr-10 max-w-sm overflow-hidden h-[70%] z-0  bg-gray-100 hover:shadow-xl hover:shadow-black/40 ",
  CardHeaderStyle:
    "relative  md:w-full md:!ml-0 sm:ml-0 sm:mr-0 sm:w-auto flex items-center justify-center rounded-none  w-full h-[70%]  ",
  AmprentaIsActive: false,
  CardBodyStyle:
    "flex justify-center flex flex-col items-center justify-center mb-5",
  CardTitleStyle: "",
  CardTextStyle: "",
  FooterStyle: "",
  isActive: false,
};

import Naidin from "../img/departamente/sportiv/cond_teo.webp";
import Cesar from "../img/departamente/sportiv/cond_cesar.webp";
import Marcu from "../img/departamente/sportiv/cond_nati.webp";
import BogdanVlad from "../img/departamente/sportiv/cond_bogdan.webp";
import Lupu from "../img/departamente/sportiv/cond_lupu.webp";
import Vaduva from "../img/departamente/sportiv/cond_Bianca.webp";

export const mappingObjectConducereSportiv = [
  {
    ImgSrc: Naidin,
    ImgAlt: "Naidin Teodora",
    CardTitle: "Naidin Teodora",
    CardText: "Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Cesar,
    ImgAlt: "Leoni Cesar-Emanuele",
    CardTitle: "Leoni Cesar-Emanuele",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Marcu,
    ImgAlt: "Marcu Natalia-Gabriela",
    CardTitle: "Marcu Natalia-Gabriela",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: BogdanVlad,
    ImgAlt: "Vlad Bogdan-Mihail ",
    CardTitle: "Vlad Bogdan-Mihail ",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Lupu,
    ImgAlt: "Lupu Dan-Mihai",
    CardTitle: "Lupu Dan-Mihai",
    CardText: "Secretar",
    FooterText: "",
  },
  {
    ImgSrc: Vaduva,
    ImgAlt: "Văduva Bianca-Ștefania",
    CardTitle: "Văduva Bianca-Ștefania ",
    CardText: "Secretar",
    FooterText: "",
  },
];

export const styledObjectConducereInvatamant = {
  styleComponent:
    "overflow-auto w-full mt-10 flex flex-wrap justify-center  p-2 sm:p-10 mt-5 sm:mt-40 md:mb-[-1px] sm:mb-40 conducereComp rounded-none ",
  style:
    "mt-4 mb-4 md:ml-10 md:mr-10 max-w-sm overflow-hidden h-[70%] z-0  bg-gray-100 hover:shadow-xl hover:shadow-black/40 ",
  CardHeaderStyle:
    "relative  md:w-full md:!ml-0 sm:ml-0 sm:mr-0 sm:w-auto flex items-center justify-center rounded-none  w-full h-[70%]  ",
  AmprentaIsActive: false,
  CardBodyStyle:
    "flex justify-center flex flex-col items-center justify-center mb-5",
  CardTitleStyle: "",
  CardTextStyle: "",
  FooterStyle: "",
  isActive: false,
};

import Naziru from "../img/departamente/Invatamant/Nazîru_Miruna.webp";
import Nanu from "../img/departamente/Invatamant/Nanu_Ana.webp";
import Dragomir from "../img/departamente/Invatamant/Dragomir_Roxana.webp";
import Gorcea from "../img/departamente/Invatamant/Gorcea_Cristina.webp";
import Pangratie from "../img/departamente/Invatamant/Pangratie_Alexandru.webp";
import Stoian from "../img/departamente/Invatamant/Stoian_Valentin.webp";
export const mappingObjectConducereInvatamant = [
  {
    ImgSrc: Naziru,
    ImgAlt: "Nazîru Miruna",
    CardTitle: "Nazîru Miruna",
    CardText: "Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Nanu,
    ImgAlt: "Nanu Ana Maria",
    CardTitle: "Nanu Ana Maria ",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Dragomir,
    ImgAlt: "Dragomir Roxana",
    CardTitle: "Dragomir Roxana",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Gorcea,
    ImgAlt: "Gorcea Cristina",
    CardTitle: "Gorcea Cristina",
    CardText: "Secretar",
    FooterText: "",
  },
  {
    ImgSrc: Pangratie,
    ImgAlt: "Pangratie Alexandru",
    CardTitle: "Pangratie Alexandru",
    CardText: "Secretar",
    FooterText: "",
  },
  {
    ImgSrc: Stoian,
    ImgAlt: "Stoian Valentin",
    CardTitle: "Stoian Valentin",
    CardText: "Secretar",
    FooterText: "",
  },
];

export const styledObjectConducereCultural = {
  styleComponent:
    "overflow-auto w-full mt-10 flex flex-wrap justify-center  p-2 sm:p-10 mt-5 sm:mt-40 md:mb-[-1px] sm:mb-40 conducereComp rounded-none ",
  style:
    "mt-4 mb-4 md:ml-10 md:mr-10 max-w-sm overflow-hidden h-[70%] z-0  bg-gray-100 hover:shadow-xl hover:shadow-black/40 ",
  CardHeaderStyle:
    "relative  md:w-full md:!ml-0 sm:ml-0 sm:mr-0 sm:w-auto flex items-center justify-center rounded-none  w-full h-[70%]  ",
  AmprentaIsActive: false,
  CardBodyStyle:
    "flex justify-center flex flex-col items-center justify-center mb-5",
  CardTitleStyle: "",
  CardTextStyle: "",
  FooterStyle: "",
  isActive: false,
};

import Cioroiu from "../img/departamente/cultural/ioana.webp";
import Lamba from "../img/departamente/cultural/bianca.webp";
import Burtan from "../img/departamente/cultural/antonia.webp";
import Tanasie from "../img/departamente/cultural/raluca.webp";
export const mappingObjectConducereCultural = [
  {
    ImgSrc: Cioroiu,
    ImgAlt: "Cioroiu Ioana - Denise",
    CardTitle: "Cioroiu Ioana - Denise",
    CardText: "Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Lamba,
    ImgAlt: "Lambă Bianca - Andreea",
    CardTitle: "Lambă Bianca - Andreea",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Burtan,
    ImgAlt: "Burtan Antonia",
    CardTitle: "Burtan Antonia",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Tanasie,
    ImgAlt: "Tănasie Raluca ",
    CardTitle: "Tănasie Raluca ",
    CardText: "Secretar",
    FooterText: "",
  },
];

export const styledObjectConducereLogistica = {
  styleComponent:
    "overflow-auto w-full mt-10 flex flex-wrap justify-center  p-2 sm:p-10 mt-5 sm:mt-40 md:mb-[-1px] sm:mb-40 conducereComp rounded-none ",
  style:
    "mt-4 mb-4 md:ml-10 md:mr-10 max-w-sm overflow-hidden h-[70%] z-0  bg-gray-100 hover:shadow-xl hover:shadow-black/40 ",
  CardHeaderStyle:
    "relative  md:w-full md:!ml-0 sm:ml-0 sm:mr-0 sm:w-auto flex items-center justify-center rounded-none  w-full h-[70%]  ",
  AmprentaIsActive: false,
  CardBodyStyle:
    "flex justify-center flex flex-col items-center justify-center mb-5",
  CardTitleStyle: "",
  CardTextStyle: "",
  FooterStyle: "",
  isActive: false,
};
import RaduAlexandru from "../img/departamente/logi/Radu.webp";
import Alin from "../img/departamente/logi/Alin.webp";
import Ichim from "../img/departamente/logi/Ichim.webp";
import Cosmin from "../img/departamente/logi/Cosmin.webp";
import Sebi from "../img/departamente/logi/Sebi.webp";

export const mappingObjectConducereLogistica = [
  {
    ImgSrc: RaduAlexandru,
    ImgAlt: "Radu Alexandru-Theodor",
    CardTitle: "Radu Alexandru-Theodor",
    CardText: "Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Alin,
    ImgAlt: "Popescu Alin-Ionut",
    CardTitle: "Popescu Alin-Ionut",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Cosmin,
    ImgAlt: "Miron George-Cosmin",
    CardTitle: "Miron George-Cosmin",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Ichim,
    ImgAlt: "Ichim Cosmin-Daniel ",
    CardTitle: "Ichim Cosmin-Daniel ",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Sebi,
    ImgAlt: "Bratu Sebastian-Adrian",
    CardTitle: "Bratu Sebastian-Adrian",
    CardText: "Secretar",
    FooterText: "",
  },
];

export const styledObjectConducereMedia = {
  styleComponent:
    "overflow-auto w-full mt-10 flex flex-wrap justify-center  p-2 sm:p-10 mt-5 sm:mt-40 md:mb-[-1px] sm:mb-40 conducereComp rounded-none ",
  style:
    "mt-4 mb-4 md:ml-10 md:mr-10 max-w-sm overflow-hidden h-[70%] z-0  bg-gray-100 hover:shadow-xl hover:shadow-black/40 ",
  CardHeaderStyle:
    "relative  md:w-full md:!ml-0 sm:ml-0 sm:mr-0 sm:w-auto flex items-center justify-center rounded-none  w-full h-[70%]  ",
  AmprentaIsActive: false,
  CardBodyStyle:
    "flex justify-center flex flex-col items-center justify-center mb-5",
  CardTitleStyle: "",
  CardTextStyle: "",
  FooterStyle: "",
  isActive: false,
};

import Lungu from "../img/departamente/media/radul.webp";
import Rux from "../img/departamente/media/rux.webp";
import Iso from "../img/departamente/media/dia.webp";
import Curi from "../img/departamente/media/curi.webp";
import Andu from "../img/departamente/media/andu.webp";
import AlexJ from "../img/departamente/media/alexj.webp";
export const mappingObjectConducereMedia = [
  {
    ImgSrc: Lungu,
    ImgAlt: "Lungu Radu",
    CardTitle: "Lungu Radu",
    CardText: "Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Iso,
    ImgAlt: "Isofache Diana",
    CardTitle: "Isofache Diana",
    CardText: "Asistent Coordonator PR",
    FooterText: "",
  },
  {
    ImgSrc: Rux,
    ImgAlt: "Apostol Ruxandra ",
    CardTitle: "Apostol Ruxandra ",
    CardText: "Asistent Coordonator PR",
    FooterText: "",
  },
  {
    ImgSrc: Andu,
    ImgAlt: "Sterpan Alexandru",
    CardTitle: "Sterpan Alexandru",
    CardText: "Asistent Coordonator FOTO",
    FooterText: "",
  },
  {
    ImgSrc: Curi,
    ImgAlt: "Curiman Teodor ",
    CardTitle: "Curiman Teodor",
    CardText: "Asistent Coordonator FOTO",
    FooterText: "",
  },
  {
    ImgSrc: AlexJ,
    ImgAlt: "Jujan Alex",
    CardTitle: "Jujan Alex",
    CardText: "Secretar",
    FooterText: "",
  },
];

export const styledObjectConducereYDD = {
  styleComponent:
    "overflow-auto w-full mt-10 flex flex-wrap justify-center  p-2 sm:p-10 mt-5 sm:mt-40 md:mb-[-1px] sm:mb-40 conducereComp rounded-none ",
  style:
    "mt-4 mb-4 md:ml-10 md:mr-10 max-w-sm overflow-hidden h-[70%] z-0  bg-gray-100 hover:shadow-xl hover:shadow-black/40 ",
  CardHeaderStyle:
    "relative  md:w-full md:!ml-0 sm:ml-0 sm:mr-0 sm:w-auto flex items-center justify-center rounded-none  w-full h-[70%]  ",
  AmprentaIsActive: false,
  CardBodyStyle:
    "flex justify-center flex flex-col items-center justify-center mb-5",
  CardTitleStyle: "",
  CardTextStyle: "",
  FooterStyle: "",
  isActive: false,
};

import Staicu from "../img/departamente/ydd/Staicu_Bianca.webp";
import RaduElena from "../img/departamente/ydd/Radu_Elena.webp";
import Paulet from "../img/departamente/ydd/Paulet_Oana.webp";
import Asavei from "../img/departamente/ydd/Asavei_Andrei.webp";
import Baroiu from "../img/departamente/ydd/Baroiu_Silvian.webp";
import Manta from "../img/departamente/ydd/Andrei_Manta.webp";

export const mappingObjectConducereYDD = [
  {
    ImgSrc: Staicu,
    ImgAlt: "Staicu Bianca-Elena",
    CardTitle: "Staicu Bianca-Elena",
    CardText: "Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: RaduElena,
    ImgAlt: "Radu Elena",
    CardTitle: "Radu Elena",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Asavei,
    ImgAlt: "Asavei Theodor-Andrei",
    CardTitle: "Asavei Theodor-Andrei",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Manta,
    ImgAlt: "Manta Andrei",
    CardTitle: "Manta Andrei",
    CardText: "Asistent Coordonator",
    FooterText: "",
  },
  {
    ImgSrc: Baroiu,
    ImgAlt: "Baroiu Silvian",
    CardTitle: "Baroiu Silvian",
    CardText: "Secretar",
    FooterText: "",
  },
  {
    ImgSrc: Paulet,
    ImgAlt: "Păuleț Oana-Maria ",
    CardTitle: "Păuleț Oana-Maria ",
    CardText: "Secretar",
    FooterText: "",
  },
];
