import Robo from "../img/Carousel/robo.jpg";
import Sim from "../img/Carousel/simulare.jpg";
import APV from "../img/Carousel/t_apv.jpg";
import Cardio from "../img/Carousel/t_cardiontone.jpg";
export const slides = [
  {
    url: Robo,
    style: " md:h-full w-full object-left-top",
  },
  {
    url: Sim,
    style: " md:h-full w-full object-left-top",
  },
  {
    url: APV,
    style: " md:h-full w-full object-left-top",
  },
  {
    url: Cardio,
    style: " md:h-full w-full object-left-top",
  },
];
